var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.data
        ? _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(" User: " + _vm._s(_vm.data.fullName) + " ")
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(" Company: " + _vm._s(_vm.data.companyName) + " ")
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(" Mobile: " + _vm._s(_vm.data.mobileNumber) + " ")
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(" Email: " + _vm._s(_vm.data.emailAddress) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(
                      " BS Points: " + _vm._s(_vm.data.walletBalance) + " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }